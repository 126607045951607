import React from "react";
import { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import { Wrapper } from "@googlemaps/react-wrapper";
import MyMapComponent from "../components/map";

import emailjs from "emailjs-com";

const Contact = () => {
  let [sent, setSent] = useState(false);

  function changeColor(event) {
    event.target.style.color = "#000";
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.GATSBY_SERVICE_ID,
        process.env.GATSBY_TEMPLATE_ID,
        e.target,
        process.env.GATSBY_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setSent(true);

    e.target.reset();
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <h1>Contact</h1>
      <div className="contact-div">
        <div>
          <h1 id="contact-nu">Neem nu contact op</h1>
          <form onSubmit={sendEmail}>
            <input
              type="text"
              id="naam"
              name="naam"
              placeholder="Naam"
              required
            />
            <input
              type="text"
              id="email"
              name="email"
              placeholder="E-mailadres"
              required
            />
            <div className="select-div">
              <select
                name="categorie"
                id="categorie"
                defaultValue=""
                required
                onChange={changeColor}
              >
                <option value="" disabled hidden>
                  Categorie
                </option>
                <option value="info">Info</option>
                <option value="gesprek">Gesprek</option>
                <option value="diagnostiek">Diagnostiek</option>
              </select>
            </div>
            <textarea
              name="beschrijving"
              id="beschrijving"
              cols="30"
              rows="8"
              placeholder="Beschrijf kort waarover je zou willen praten..."
              required
            ></textarea>
            <input type="submit" value="Verstuur" className="submit" />
          </form>
          {sent && (
            <div className="confirmation-div">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.775 31.0709C15.4703 31.3756 15.0589 31.5508 14.6248 31.5508C14.1906 31.5508 13.7792 31.3832 13.4746 31.0709L4.30347 21.8922C3.35132 20.9401 3.35132 19.3938 4.30347 18.4493L5.45366 17.2991C6.40581 16.3469 7.94448 16.3469 8.89663 17.2991L14.6248 23.0272L30.1029 7.54907C31.055 6.59692 32.6013 6.59692 33.5458 7.54907L34.696 8.69927C35.6482 9.65142 35.6482 11.1977 34.696 12.1422L15.775 31.0709Z"
                  fill="#6B8F88"
                />
              </svg>
              <p className="confirmation-text">
                Bedankt! Ik neem zo snel mogelijk contact met je op.
              </p>
            </div>
          )}
        </div>
        <div className="contact-information">
          <p>
            <span className="semibold">Lauren Maenen</span>
            <br />
            lauren.maenen@gmail.com
            <br />
            +32 472 46 40 59
          </p>
          <p>
            <span className="semibold">Huisartsenpraktijk Kwadrant (ELP)</span>
            <br />
            Burgemeester Henrylaan 103
            <br />
            3650 Dilsen-Stokkem
            <br />
            <a
              href="https://www.huisartsenkwadrant.be"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              www.huisartsenkwadrant.be
            </a>
          </p>
          <p>
            <span className="semibold">Praktijk Dandelion</span>
            <br />
            Arkstraat 32
            <br />
            3670 Meeuwen
            <br />
            <a
              href="https://www.sigridclaes.be"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              www.sigridclaes.be
            </a>
          </p>
          <p>
            <span className="semibold">Huisartsenpraktijk Loksbergen</span>
            <br />
            Kauwplasstraat 5
            <br />
            3545 Halen
            <br />
            <a
              href="https://www.huisartsen-loksbergen.be"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              www.huisartsen-loksbergen.be
            </a>
          </p>
          <p>
            <span className="semibold">Groepspraktijk 'Let's Grow'</span>
            <br />
            Langdorpsesteenweg 1C <br />
            3200 Aarschot <br />
            <a
              href="https://www.letsgrow-aarschot.com"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              www.letsgrow-aarschot.com
            </a>
          </p>
        </div>
      </div>
      {/* <Wrapper apiKey={process.env.GATSBY_API_KEY}>
        <MyMapComponent />
      </Wrapper> */}
    </Layout>
  );
};

export default Contact;
